import React, { useState, useEffect } from 'react';
import AddButton from '../../components/Shared/AddButton/AddButton';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import Table from '../../components/Shared/Table/BooksTable';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Books.module.css';

import { BooksDTO, BooksResponseDTO } from '../../dto/BooksDTO';
import { BooksService } from '../../services/BooksService';
import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons';
import { SelectChangeEvent } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { ErrorResponse } from '../../dto/ErrorsDTO';

interface PublishResponseDTO {
    message: string;
    book: BooksDTO
}

const Books: React.FC = () => {
    const columns = [
        { title: 'Cover' },
        { title: 'Title' },
        { title: 'Grade' },
        { title: 'Publisher' },
        { title: 'Category' },
        { title: 'Price' },
        { title: 'CreatedDate' },
    ];

    const [loading, setLoading] = useState(false)

    const [books, setBooks] = useState<BooksDTO[]>([]);
    const [total, setTotal] = useState(0)
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [pageSize, setPageSize] = useState(10);

    const [selectedBook, setSelectedBook] = useState<BooksDTO | null>(null);
    const [mode, setMode] = useState<'archive' | 'delete' | 'publish'>('archive');
    const [showModal, setShowModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    //fetch books
    useEffect(() => {
        const fetchBooksData = async () => {
            try {
                setLoading(true)
                const archived = activeButton === 1 ? true : null
                const published = activeButton === 0 ? true : activeButton === 2 ? false : null
                const book: BooksResponseDTO = await BooksService.getBooks( pageSize, currentPage, archived, published, searchTerm);
                if (book && book.books && Array.isArray(book.books)) {
                    setBooks(book.books);
                    setTotal(book.total || 0)
                }
        
            } catch (error:any) {
                showErrorToast('Error fetching books: '+error.message)

                
            } finally {
                setLoading(false)
            }
        };

        fetchBooksData();
    }, [searchTerm, activeButton, pageSize, currentPage]);


    const handleToggleButtons = (index: number) => {
        setLoading(true)
        setCurrentPage(1)
        setActiveButton(index)
    }

    const handleSearchChange = (value: string) => {
        setLoading(true)
        setCurrentPage(1)

        setSearchTerm(value);
    };

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setCurrentPage(1)
        setPageSize(Number(event.target.value));
    };

    const handlePageChange = (page: number) => {
        setLoading(true)
        setCurrentPage(page);
    };

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedBook) {
                try {
                let message = '';

                if (mode === 'archive') {
                    message = await BooksService.toggleArchive( selectedBook._id);
                    showSuccessToast(message);
                } else if (mode === 'delete') {
                    message = await BooksService.deleteBook( selectedBook._id);
                    showSuccessToast(message);
                } else if (mode === 'publish') {
                    message = await BooksService.togglePublish( selectedBook._id);
                    showSuccessToast(message);
                }
                if(mode==='archive' && activeButton ===1){
                    setBooks(books.filter(book => book._id !== selectedBook._id));
                }
                else if(mode==='publish' && (activeButton ===2 || activeButton===0)){
                    setBooks(books.filter(book => book._id !== selectedBook._id));
                }else if(mode==='delete'){
                    setBooks(books.filter(book => book._id !== selectedBook._id));
                }
                setSelectedBook(null);

                } catch (error: any) {
                    const errorMessage = error.message 
                    
                    if (mode === 'archive') {
                        showErrorToast(`Error toggling archive book: ${errorMessage}`);
                    } else if (mode === 'delete') {
                        showErrorToast(`Error deleting book: ${errorMessage}`);
                    } else if (mode === 'publish') {
                        showErrorToast(`Error toggling publish book: ${errorMessage}`);
                    }
                
                
            }
        }
        setShowModal(false);
    };
    

const handleCancelAction = (): void => {
    setSelectedBook(null);
    setShowModal(false);
};

const handleArchiveClick = (book: BooksDTO): void => {
    setSelectedBook(book);
    setMode('archive');
    setShowModal(true);
};

const handlePublishClick = (book: BooksDTO): void => {
    setSelectedBook(book);
    setMode('publish');
    setShowModal(true);
};

const handleDeleteClick = (book: BooksDTO): void => {
    setSelectedBook(book);
    setMode('delete');
    setShowModal(true);
};

const handleEditClick = (index: number): void => {
    const book = books[index];
    if (book) {
        navigate(`/books/edit/${book._id}`);
    }
};

const handleViewClick = (index: number): void => {
    const book = books[index];
    if (book) {
        navigate(`/books/view/${book._id}`);
    }
};

const handlePreviewClick = (url: string) => {
    window.open(url, '_blank')
}

const label = [
    { label_name: "Published", index: 0 },
    { label_name: "Archived", index: 1 },
    { label_name: "Unpublished", index: 2 },
];

const formatDate = (oldDate: string): string => {
    if (!oldDate) {
        return " "
    }
    const date = new Date(oldDate);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    return formattedDate;
}

const [sortColumn, setSortColumn] = useState<string | null>(null);
const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

const sortedBooks =React.useMemo(() => {
    if (!sortColumn) return books;

    const parseValue = (value: any) => {
        if (value === null || value === undefined) return null;
        if (typeof value === 'string') {
            if (!isNaN(Date.parse(value))) return new Date(value).getTime();
            const numberMatch = value.match(/\d+/);
            if (numberMatch) return parseInt(numberMatch[0], 10);
            return value.toLowerCase();
        }
        return value;
    };

    return [...books].sort((a, b) => {
        const aValue = parseValue(a[sortColumn as keyof BooksDTO]);
        const bValue = parseValue(b[sortColumn as keyof BooksDTO]);

        if (aValue === null && bValue !== null) return sortDirection === 'asc' ? 1 : -1;
        if (aValue !== null && bValue === null) return sortDirection === 'asc' ? -1 : 1;
        if (aValue === null && bValue === null) return 0;

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
    });
}, [books, sortColumn, sortDirection]);


return (
    <div className={styles.container}>
        <div className={styles.content}>
            <ToggleButtons labels={label} activeButton={activeButton} setActiveButton={handleToggleButtons} />

            <div className='flex'>
                <Link to="/books/add" className={styles.link}>
                    <AddButton label="ADD BOOK" />
                </Link>

                <ExportButton label="Export" exportType='books' />
            </div>
        </div>
        <div className={styles.content2}>
            <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange} />
            <PaginationInfo totalItems={total} pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange} />

        </div>
        <Table
                handleSort={handleSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            columns={columns}
            data={sortedBooks?.map((book) => ({
                _id: book._id,
                Cover: book.bookCoverUrl,
                Title: book.bookTitle,
                Grade: book.grades?.map(grade => grade.engName).join(', '),
                Publisher: book.publisher?.fullname,
                Category: book.category?.engName,
                Price: book.mainPrice,
                CreatedDate: formatDate(book.createdAt),
                Archived: book.archived,
                Published: book.published,
                Url: book.mobileUrl || ''
            }))}
            onEdit={handleEditClick}
            onView={handleViewClick}
            onDelete={handleDeleteClick}
            loading={loading}
            onArchive={handleArchiveClick}
            onPreview={handlePreviewClick}
            onPublish={handlePublishClick}

        />

        <div className={styles.content3}>
            <Pagination3 totalPages={Math.ceil(total / pageSize)} currentPage={currentPage} onPageChange={handlePageChange} />
        </div>

        <ArchiveModal
            setIsOpen={setShowModal}
            isOpen={showModal}
            onConfirm={handleConfirmAction}
            onCancel={handleCancelAction}
            header={`Sure you want to ${mode === 'archive' ? selectedBook?.Archived===false? 'archive' : "Unarchive" : mode === 'delete' ? 'delete' : mode === 'publish' ? selectedBook?.Published===true? 'Unpublish': "Publish" : ''} this book?`}
            parag={`Sure you want to ${mode === 'archive' ? selectedBook?.Archived===false? 'archive' : "Unarchive" : mode === 'delete' ? 'delete' : mode === 'publish' ? selectedBook?.Published===true? 'Unpublish': "Publish" : ''} this book?`}
            mode={mode}
            buttonText={mode === 'archive' ? selectedBook?.Archived===false ? 'Yes, archive' : "Yes, Unarchive" : mode === 'delete' ? 'Yes, delete' : mode === 'publish' ? selectedBook?.Published===true? 'Yes, Unpublish' : "Yes, Publish" : ""}
        />
    </div>
);
}

export default Books;
