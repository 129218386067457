import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class CategoriesService {
    public static getCategories = async ( page: number, limit: number, archived: boolean, name: string): Promise<any> => {
        try {
            const params = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                archived: String(archived),
                ...(name && { name }),
            });

            const resp = await FetchAuth(`${endpoint}/category/getCategoriesAdmin?${params.toString()}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

       

            return await resp.json();
        } catch (error) {
             throw error;
        }
    }

    public static getCategoryById = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/${_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

         

            return await resp.json();
        } catch (error) {
             throw error;
        }
    };

    public static createCategory = async ( categoryData: FormData): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/create`, {
                method: "POST",
                body: categoryData
            });

  

            return await resp.json();
        } catch (error) {
             throw error;
        }
    };

    public static updateCategory = async ( _id: string, formData: FormData): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/${_id}`, {
                method: "PUT",
                body: formData
            });

        

            return await resp.json();
        } catch (error) {
             throw error;
        }
    };

    public static toggleActivate = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/toggleArchiveStatus/${_id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

          

            const data = await resp.json();
            return data.message;
        } catch (error) {
             throw error;
        }
    };

    public static deleteCategory = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/category/delete/${_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                }
            });

           

            const data = await resp.json();
            return data.message;
        } catch (error) {
             throw error;
        }
    };

    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportCategories?format=${format}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });
 

            return await resp.blob();
        } catch (error) {
             throw error;
        }
    };
}
