  import React, { useState, useEffect } from 'react';
  import AddButton from '../../components/Shared/AddButton/AddButton';
  import ExportButton from '../../components/Shared/ExportButton/ExportButton';
  import SearchBar from '../../components/Shared/SearchBar/SearchBar';
  import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
  import Pagination3 from '../../components/Shared/Pagination/Pagination3';
  import { Link, useNavigate } from 'react-router-dom';
  import { SchoolsDTO } from '../../dto/SchoolsDTO';
  import { SchoolsService } from '../../services/SchoolsService';
  import Table from '../../components/Shared/Table/CategTable';
  import ToggleButtons from '../../components/Shared/ToggleButtons/ToggleButtons'; 
  import styles from './Schools.module.css';
  import { SelectChangeEvent } from '@mui/material';
  import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
  import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
  import { GradesDTO } from '../../dto/GradesDTO';

  const Schools: React.FC = () => {
    const columns = [
      { title: 'Name' },
      { title: 'Country' },
      { title: 'Region' },
      { title: 'Casa ID' },
      { title: 'Total Batches' },
      { title: 'Created Date' },
    ];

    const navigate = useNavigate();
    const [schools, setSchools] = useState<SchoolsDTO[]>([]);
    const [total,setTotal]=useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [activeButton, setActiveButton] = useState(0); 
    const [loading, setLoading] = useState<boolean>(true); 
    const [searchTerm,setSearchTerm] = useState<string>("");
    const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);
    const [mode, setMode] = useState<'archive' | 'delete'>('archive'); 
    const [showModal, setShowModal] = useState(false);


    const [pageSize, setPageSize] = useState(10);
      const handleSearchChange = (value: string) => {
        setLoading(true)
        setCurrentPage(1)

        setSearchTerm(value); 
    };

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
      setLoading(true);
      setPageSize(Number(event.target.value));
      setCurrentPage(1)
    };

    useEffect(() => {
      const fetchSchoolsData = async () => {
        try {
          const archived:boolean=activeButton===1?true:false;

          
            const response = await SchoolsService.getSchools(archived,currentPage,pageSize,searchTerm);
            if (response && response.schools && Array.isArray(response.schools)) {
              setSchools(response.schools);
              setTotal(response.total)
            }
          
        } catch (error:any) {
          showErrorToast('Error fetching schools: '+error.message)
          setSchools([]);
        }finally{
          setLoading(false)
        }
      };

      fetchSchoolsData();
    }, [activeButton,pageSize,currentPage,searchTerm]);

    const handleEditClick = (index: number): void => {
      const school = schools[index];
      if (school) {
        navigate(`/schools/edit/${school._id}`);
      }
    };

    const handleViewClick = (index: number): void => {
      const school = schools[index];
      if (school) {
        navigate(`/schools/view/${school._id}`);
      }
    };

    const handleDeleteClick = (id:string): void => {
        setSelectedSchoolId(id);
        setMode('delete'); 
        setShowModal(true);
    };
    
      const handleConfirmAction = async (): Promise<void> => {
      if (selectedSchoolId) {
          setLoading(true)
          
          try {
              setSelectedSchoolId(null);
              if (mode === 'archive') {
                  const message=await SchoolsService.toggleArchive( selectedSchoolId);
                  showSuccessToast(message);
              } else if (mode === 'delete') {
                  const message=await SchoolsService.deleteSchool( selectedSchoolId);
                  showSuccessToast(message);
              }
              setSchools(schools.filter(publisher => publisher._id !== selectedSchoolId));
          } catch (error:any) {
              showErrorToast(`Error ${mode === 'archive' ? 'toggling archive' : 'deleting'} school `+error.message);

          }finally {
              setLoading(false)
          }
        
      }
      setShowModal(false);
    };  

    const handleCancelAction = (): void => {
      setSelectedSchoolId(null);
      setShowModal(false);
    };

    const handleArchiveClick = (id: string): void => {
      setSelectedSchoolId(id);
      setMode('archive'); 
      setShowModal(true);
    };

    const handlePageChange = (page: number) => {
      setLoading(true)
      setCurrentPage(page);
    };

    const handleChangeActivebuttons=(index:number)=>{
      setLoading(true);
      setCurrentPage(1)

      setActiveButton(index)
    }

    const label = [
      {
          label_name: "Published",
          index: 0
      }, 
      { 
          label_name: "Archived",
          index: 1
      }
    ]

    const formatDate = (oldDate:string): string => {
      if(!oldDate){
          return ""
      }
      const date = new Date(oldDate);
      const formattedDate = date.toLocaleDateString('en-US', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric' 
      });
      return formattedDate;
    }

    const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (column: string) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    };

  const sortedSchools =React.useMemo(() => {
      if (!sortColumn) return schools;

      const parseValue = (value: any) => {
          if (value === null || value === undefined) return '';
          if (typeof value === 'string') {
              if (!isNaN(Date.parse(value))) return new Date(value).getTime();
              const numberMatch = value.match(/\d+/);
              if (numberMatch) return parseInt(numberMatch[0], 10);
              return value.toLowerCase();
          }
          return value;
      };

      return [...schools].sort((a, b) => {
          const aValue = parseValue(a[sortColumn as keyof SchoolsDTO]);
          const bValue = parseValue(b[sortColumn as keyof SchoolsDTO]);

          if (aValue === null && bValue !== null) return sortDirection === 'asc' ? 1 : -1;
          if (aValue !== null && bValue === null) return sortDirection === 'asc' ? -1 : 1;
          if (aValue === null && bValue === null) return 0;

          if (typeof aValue === 'number' && typeof bValue === 'number') {
              return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
          }
          if (typeof aValue === 'string' && typeof bValue === 'string') {
              return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
          return 0;
      });
  }, [schools, sortColumn, sortDirection]);

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <ToggleButtons
                labels={label}
                activeButton= {activeButton}
                setActiveButton={handleChangeActivebuttons}
          />

          <div className="flex items-center">
            <Link to="/schools/add" className={styles.link}>
              <AddButton label="ADD SCHOOL" />
            </Link>
            <ExportButton label="Export" exportType='schools'/>
          </div>
        </div>
        <div className={styles.content2}>
          <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
          <div className={styles.paginationContainer}>
            <PaginationInfo totalItems={total} pageSize={pageSize}
                  handlePageSizeChange={handlePageSizeChange}/>
          </div>
        </div>
        <Table
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          columns={columns}
          data={sortedSchools.map((school) => ({
            '_id': school._id || '',
            'Name': school.name || '',
            'Country': school.country || '',
            'Region': school.region || '',
            'Casa ID': school.casaId ,
            'Total Batches': school.batchCount,
            'Created Date': formatDate(school.dateCreated) || '',
            'Archived':school.archived || '',
          }))}
          onEdit={handleEditClick}
          onView={handleViewClick}
          onDelete={handleDeleteClick}
          onArchive={handleArchiveClick}
          loading={loading}
        />
        <div className={styles.content3}>
          <Pagination3
            totalPages={Math.ceil(total / pageSize)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
        <ArchiveModal
        setIsOpen={setShowModal}
                  isOpen={showModal}
                  onConfirm={handleConfirmAction}
                  onCancel={handleCancelAction}
                  header={`Sure you want to ${mode==='archive' ? activeButton===0?'archive':"unarchive" : 'delete'} this school?`}
                  parag={`Are you sure you want to ${mode==='archive'?activeButton===0?"archive":'unarchive':'delete'} this school?`}
                  mode={mode}
                  buttonText={mode==='archive' ? activeButton===0?'Yes, archive':"Yes, unarchive" : 'Yes, delete'}
              />
      </div>
    );
  };

  export default Schools;
