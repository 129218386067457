import React, { useState, useEffect } from 'react';
import ExportButton from '../../components/Shared/ExportButton/ExportButton';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';

import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../../components/Shared/Table/CategTable';
import styles from './ContactUs.module.css';
import { SelectChangeEvent } from '@mui/material';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { contactUsDTo } from '../../dto/ContactUsDTO';
import { ContactUsService } from '../../services/ContactUsService';

const ContactUs: React.FC = () => {
  const columns = [
    { title: 'ID' },
    { title: 'Name' },
    { title: 'Email Address' },
    { title: 'Message' },
    { title: 'Created Date' },
  ];

  const [message, setMessage] = useState<contactUsDTo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);  
  const [searchTerm,setSearchTerm] = useState<string>("");
  const [total,setTotal]=useState(0)
  const [selectedMessageId,setSelectedMessageId]=useState<string|null>(null);
  const [mode,setMode]=useState<'delete'>('delete')
  const [showModal,setShowModal]=useState(false);
  const handleSearchChange = (value: string) => {
      setLoading(true)
      setCurrentPage(1)

      setSearchTerm(value); 
  };
  
  const [pageSize, setPageSize] = useState(10);
  const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
    setLoading(true)
    setPageSize(Number(event.target.value));
  };

  useEffect(() => {
    const fetMessages = async () => {
      try {
          const response = await ContactUsService.getMessages(currentPage,pageSize,searchTerm);
          
          if (response && response.messages && Array.isArray(response.messages)) {
            setMessage(response.messages);
            setTotal(response.total)
          } else {
            console.error('Expected an array in response.data but got:', response);

          }
      } catch (error:any) {
        showErrorToast('Error fetching messages: '+error.message)
        
        setMessage([]);
      }finally{
        setLoading(false)
      }
    };

    fetMessages();
  }, [currentPage,pageSize,searchTerm]);




  const handlePageChange = (page: number) => {
    setLoading(true)
    setCurrentPage(page);
  };

  

  const handleDeleteClick = (id:string): void => {
    setSelectedMessageId(id);
    setMode('delete');
    setShowModal(true);
};
  
const handleConfirmAction = async (): Promise<void> => {
  if (selectedMessageId) {
      setLoading(true)
          try {
            if (mode === 'delete') {
              await ContactUsService.deleteMessage(selectedMessageId);
              showSuccessToast("Message deleted successfully.");
            }
            setMessage(message.filter(publisher => publisher._id !== selectedMessageId));
          } catch (error:any) {
            
            showErrorToast(`Error deleting message: `+error.message);
            
          }finally {
              setSelectedMessageId(null);
              setLoading(false)
          }
    }
  setShowModal(false);
};  
const handleCancelAction = (): void => {
  setSelectedMessageId(null);
  setShowModal(false);
};


  const formatDate = (oldDate:string): string => {
    if(!oldDate){
        return " "
    }
    const date = new Date(oldDate);
    const formattedDate = date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    });
    return formattedDate;
}

const [sortColumn, setSortColumn] = useState<string | null>(null);
const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

const sortedMessages =React.useMemo(() => {
    if (!sortColumn) return message;

    const parseValue = (value: any) => {
        if (value === null || value === undefined) return null;
        if (typeof value === 'string') {
            if (!isNaN(Date.parse(value))) return new Date(value).getTime();
            const numberMatch = value.match(/\d+/);
            if (numberMatch) return parseInt(numberMatch[0], 10);
            return value.toLowerCase();
        }
        return value;
    };

    return [...message].sort((a, b) => {
        const aValue = parseValue(a[sortColumn as keyof contactUsDTo]);
        const bValue = parseValue(b[sortColumn as keyof contactUsDTo]);

        if (aValue === null && bValue !== null) return sortDirection === 'asc' ? 1 : -1;
        if (aValue !== null && bValue === null) return sortDirection === 'asc' ? -1 : 1;
        if (aValue === null && bValue === null) return 0;

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
    });
}, [message, sortColumn, sortDirection]);

  return (
    <div className={styles.container}>
      <div className={styles.content2}>
        <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
        <div style={{display:'flex',}}>
          <PaginationInfo totalItems={total} pageSize={pageSize}
                  handlePageSizeChange={handlePageSizeChange}/>
            <ExportButton label="Export" exportType='message'/>
          </div>

      </div>
      <Table
        handleSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        columns={columns}
        data={sortedMessages.map((grade) => ({
          "_id":grade._id,
          "ID":grade._id,
          'Name': grade.fullname || '',
          'Email Address': grade.email || '',
          'Message': grade.message || '',
          'Created Date': formatDate(grade.createdAt) || '',
        }))}
        onDelete={handleDeleteClick}
        loading={loading}
      />
      <div className={styles.content3}>
        <Pagination3
          totalPages={Math.ceil(total / pageSize)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <ArchiveModal
             setIsOpen={setShowModal}

              isOpen={showModal}
              onConfirm={handleConfirmAction}
              onCancel={handleCancelAction}
              header={`Sure you want to Delete this message?`}
              parag={`Are you sure you want to delete this message?`}
              mode={mode}
              buttonText= 'Yes, delete'
            />
    </div>
  );
};

export default ContactUs;
