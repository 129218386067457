import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';

import { ReactComponent as MainLogo } from '../../../images/Login/MainLogo.svg';
import { ReactComponent as DashboardIcon } from '../../../images/SideBar/Dashboard.svg';
import { ReactComponent as DashboardIconBold } from '../../../images/SideBar/DashboardB.svg';
import { ReactComponent as PublishedIcon } from '../../../images/SideBar/Publishers.svg';
import { ReactComponent as PublishedIconBold } from '../../../images/SideBar/PublishersB.svg';
import { ReactComponent as SchoolsIcon } from '../../../images/SideBar/Schools.svg';
import { ReactComponent as SchoolsIconBold } from '../../../images/SideBar/SchoolsB.svg';
import { ReactComponent as BooksIcon } from '../../../images/SideBar/Books.svg';
import { ReactComponent as BooksIconBold } from '../../../images/SideBar/BooksB.svg';
import { ReactComponent as UsersIcon } from '../../../images/SideBar/Users.svg';
import { ReactComponent as UsersIconBold } from '../../../images/SideBar/UsersB.svg';
import { ReactComponent as LogoutIcon } from '../../../images/SideBar/Logout.svg';
import { ReactComponent as CategoriesIcon } from '../../../images/SideBar/Categories.svg';
import { ReactComponent as CategoriesIconBold } from '../../../images/SideBar/CategoriesB.svg';
import { ReactComponent as GradesIcon } from '../../../images/SideBar/Grades.svg';
import { ReactComponent as GradesIconBold } from '../../../images/SideBar/GradesB.svg';
import { ReactComponent as AnnouncementsIcon } from '../../../images/SideBar/Announcements.svg';
import { ReactComponent as AnnouncementsIconBold } from '../../../images/SideBar/AnnouncementB.svg';
import { ReactComponent as ReportsIcon } from '../../../images/SideBar/Reports.svg';
import { ReactComponent as ReportsIconBold } from '../../../images/SideBar/ReportsB.svg';
import { ReactComponent as AdminAccountsIcon } from '../../../images/SideBar/Admin.svg';
import { ReactComponent as AdminAccountsIconBold } from '../../../images/SideBar/AdminAccountsB.svg';
import { ReactComponent as BatchIconBold } from '../../../images/SideBar/BatchIconBold.svg';
import { ReactComponent as ContactUsIcon } from '../../../images/SideBar/ContactUsIcon.svg';
import { ReactComponent as ContactUsIconBold } from '../../../images/SideBar/contactusIconBold.svg';
import { auth } from '../../../firebase/firebaseConfig';
import { UserContext } from '../../../UserContext';

interface SidebarProps {
  role: 'admin' | 'editor' | 'sales' | '';
}

const Sidebar: React.FC<SidebarProps> = ({ role }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>('dashboard');
  const {logOut} = useContext(UserContext);

  useEffect(() => {
    const pathToActiveItem: Record<string, string> = {
      'publishers': 'published',
      'schools': 'schools',
      'dashboard': 'dashboard',
      'books': 'books',
      'batches': 'batches',
      'users': 'users',
      'categories': 'categories',
      'grades': 'grades',
      'contactus': 'contactus',
      'announcements': 'announcements',
      'reports': 'reports',
      'adminaccounts': 'adminaccounts',
    };


  const basePath = location.pathname.split('/')[1];

  
  setActiveItem(pathToActiveItem[basePath] || null);  }, [location.pathname]);

  const handleMenuItemClick = (item: string) => {
    setActiveItem(item);
  };

  const handleLogout = async() => {
    await logOut()
      
  
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <MainLogo />
      </div>
      {role === 'admin' || role === 'editor' ? (
  <>
    <div className={styles.sectionTitle}>MAIN MENU</div>

    <Link
      to="/dashboard"
      className={`${styles.menuItem} ${activeItem === 'dashboard' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('dashboard')}
    >
      {activeItem === 'dashboard' ? <DashboardIconBold className={styles.icon} /> : <DashboardIcon className={styles.icon} />}
      Dashboard
    </Link>

    <Link
      to="/publishers"
      className={`${styles.menuItem} ${activeItem === 'published' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('published')}
    >
      {activeItem === 'published' ? <PublishedIconBold className={styles.icon} /> : <PublishedIcon className={styles.icon} />}
      Publishers
    </Link>

    <Link
      to="/schools"
      className={`${styles.menuItem} ${activeItem === 'schools' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('schools')}
    >
      {activeItem === 'schools' ? <SchoolsIconBold className={styles.icon} /> : <SchoolsIcon className={styles.icon} />}
      Schools
    </Link>

    <Link
      to="/books"
      className={`${styles.menuItem} ${activeItem === 'books' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('books')}
    >
      {activeItem === 'books' ? <BooksIconBold className={styles.icon} /> : <BooksIcon className={styles.icon} />}
      Books
    </Link>

    <Link
      to="/batches"
      className={`${styles.menuItem} ${activeItem === 'batches' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('batches')}
    >
      {activeItem === 'batches' ? <BatchIconBold className={styles.icon} /> :<BatchIconBold className={styles.icon} />}
      Batches
    </Link>

    <Link
      to="/users"
      className={`${styles.menuItem} ${activeItem === 'users' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('users')}
    >
      {activeItem === 'users' ? <UsersIconBold className={styles.icon} /> : <UsersIcon className={styles.icon} />}
      Users
    </Link>

    <div className={styles.sectionTitle}>FILTERS</div>

    <Link
      to="/categories"
      className={`${styles.menuItem} ${activeItem === 'categories' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('categories')}
    >
      {activeItem === 'categories' ? <CategoriesIconBold className={styles.icon} /> : <CategoriesIcon className={styles.icon} />}
      Categories
    </Link>

    <Link
      to="/grades"
      className={`${styles.menuItem} ${activeItem === 'grades' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('grades')}
    >
      {activeItem === 'grades' ? <GradesIconBold className={styles.icon} /> : <GradesIcon className={styles.icon} />}
      Grades
    </Link>

    <div className={styles.sectionTitle}>INFORMATION</div>

    <Link
      to="/contactus"
      className={`${styles.menuItem} ${activeItem === 'contactus' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('contactus')}
    >
      {activeItem === 'contactus' ? <ContactUsIconBold className={styles.icon} /> : <ContactUsIcon className={styles.icon} />}
      Contact Us
    </Link>


    <Link
      to="/announcements"
      className={`${styles.menuItem} ${activeItem === 'announcements' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('announcements')}
    >
      {activeItem === 'announcements' ? <AnnouncementsIconBold className={styles.icon} /> : <AnnouncementsIcon className={styles.icon} />}
      Announcements
    </Link>

     

    {role === 'admin' && (
      <>
       <Link
        to="/reports"
        className={`${styles.menuItem} ${activeItem === 'reports' ? styles.active : ''}`}
        onClick={() => handleMenuItemClick('reports')}
      >
        {activeItem === 'reports' ? <ReportsIconBold className={styles.icon} /> : <ReportsIcon className={styles.icon} />}
        Reports
      </Link>

        <div className={styles.sectionTitle}>ADMIN</div>

        <Link
          to="/adminaccounts"
          className={`${styles.menuItem} ${activeItem === 'adminaccounts' ? styles.active : ''}`}
          onClick={() => handleMenuItemClick('adminaccounts')}
        >
          {activeItem === 'adminaccounts' ? <AdminAccountsIconBold className={styles.icon} /> : <AdminAccountsIcon className={styles.icon} />}
          Admin Accounts
        </Link>
      </>
    )}
  </>
) : role === 'sales' ? (
  <>
    <div className={styles.sectionTitle}>MAIN MENU</div>

    <Link
      to="/reports"
      className={`${styles.menuItem} ${activeItem === 'reports' ? styles.active : ''}`}
      onClick={() => handleMenuItemClick('reports')}
    >
      {activeItem === 'reports' ? <ReportsIconBold className={styles.icon} /> : <ReportsIcon className={styles.icon} />}
      Reports
    </Link>
  </>
) : null}


      <div className={`${styles.menuItem} ${styles.logout}`} onClick={handleLogout}>
        <LogoutIcon className={styles.icon} /> Logout
      </div>
    </div>
  );
};

export default Sidebar;
