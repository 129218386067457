import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class ReportsService {
    // Get yearly access code usage per school
    public static getYearlyAccessCodePerSchool = async (
        
        schoolId: string,
        bookId: string,
        year: string
    ): Promise<any> => {
        try {
            const resp = await FetchAuth(
                `${endpoint}/admin/getAccessCodeUsagePerSchool/${schoolId}/${bookId}?year=${year}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/json",
                    },
                }
            );
            
            

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };

    // Get yearly access code usage per batch
    public static getYearlyAccessCodePerBatch = async (
        
        batchId: string,
        bookId: string,
        year: string
    ): Promise<any> => {
        try {
            const resp = await FetchAuth(
                `${endpoint}/admin/getAccessCodeUsagePerBookByBatch/${bookId}/${batchId}?year=${year}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/json",
                    },
                }
            );
            
     
            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };

    // Get access code usage per publisher
    public static getAccessCodeUsagePerPublisher = async (
        
        publisherId: string,
        year: string
    ): Promise<any> => {
        try {
            const resp = await FetchAuth(
                `${endpoint}/admin/getAccessCodeUsagePerPublisher/${publisherId}?year=${year}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/json",
                    },
                }
            );
            
       

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };

    // Get access code usage per batch
    public static getAccessCodeUsagePerBatch = async (
        
        batchId: string,
        year: string
    ): Promise<any> => {
        try {
            const resp = await FetchAuth(
                `${endpoint}/admin/getAccessCodeUsagePerBatch/${batchId}?year=${year}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/json",
                    },
                }
            );
    
            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };

    // Get top downloaded books with filters
    public static getTopDownloadedBooks = async (
        
        page: number,
        limit: number,
        name: string,
        publisherId?: string,
        categoryId?: string,
        type?: string
    ): Promise<any> => {
        try {
            const queryParams = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                ...(publisherId !== undefined && { publisherId: String(publisherId) }),
                ...(categoryId !== undefined && { categoryId: String(categoryId) }),
                ...(type && { type: type }),
                ...(name && { name: name }),
            });

            const resp = await FetchAuth(
                `${endpoint}/admin/getTopDownloadedBooks?${queryParams.toString()}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': "application/json",
                    },
                }
            );
            
            

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };
}
