import { BooksDTO, newBookDTO } from "../dto/BooksDTO";
import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class BooksService {
    public static getBooks = async ( limit: number, page: number, archived: boolean | null, published: boolean | null, title: string | null): Promise<any> => {
        try {
            const params = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                ...(archived !== null && { archived: String(archived) }),
                ...(published !== null && { published: String(published) }),
                ...(title && { title })
            });

            const resp = await FetchAuth(`${endpoint}/book/adminGetBooks?${params.toString()}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            });

            return await resp.json();
        } catch (error) {
            throw error;
        }
    }

    public static getBooksByPublisher = async ( id: string | null): Promise<BooksDTO[]> => {
        try {
            const params = new URLSearchParams({
                ...(id && { publisherId: id })
            });

            const resp = await FetchAuth(`${endpoint}/book/getBooks?${params.toString()}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            });


            return await resp.json();
        } catch (error) {
            throw error;
        }
    }

    public static getBookById = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/book/getBookDetails/${_id}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            });

            return await resp.json();
        } catch (error) {
            throw error;
        }
    }


    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportBooks?format=${format}`, {
                method: 'GET',
                headers: { "Content-Type": "application/json" }
            });

            return await resp.blob();
        } catch (error) {
            throw error;
        }
    }

    public static getFileAccessCode = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportAccessCodes?format=${format}`, {
                method: 'GET',
                headers: { "Content-Type": "application/json" }
            });

   

            return await resp.blob();
        } catch (error) {
            throw error;
        }
    }

    public static togglePublish = async ( id: string): Promise<string> => {
        try {
            const resp = await FetchAuth(`${endpoint}/book/togglePublish/${id}`, {
                method: 'PATCH',
                headers: { 'Content-Type': "application/json" }
            });

       
            const data = await resp.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }

    public static toggleArchive = async ( id: string): Promise<string> => {
        try {
            const resp = await FetchAuth(`${endpoint}/book/toggleArchive/${id}`, {
                method: 'PATCH',
                headers: { 'Content-Type': "application/json" }
            });


            const data = await resp.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }

    public static deleteBook = async ( id: string): Promise<string> => {
        try {
            const resp = await FetchAuth(`${endpoint}/book/deleteBook/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': "application/json" }
            });

        

            const data = await resp.json();
            return data.message;
        } catch (error) {
            throw error;
        }
    }

}
