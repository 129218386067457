import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Dropdown from '../../components/Shared/Textfields/FillerDropdown';
import styles from './SchoolForm.module.css';
import { SchoolsDTO } from '../../dto/SchoolsDTO';
import { SchoolsService } from '../../services/SchoolsService';
import { showSuccessToast,showErrorToast } from '../../components/Shared/Toastify/toastHelper';
import Table from '../../components/Shared/Table/Table';
import { ReactComponent as AddCircleIcon } from '../../images/Shared/Add_circle.svg'; 
import { BatchDTO } from '../../dto/BatchesDTO';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { CircularProgress } from '@mui/material';
 
import Languages from '../../utils/LanguagesUtil';
import Countries from '../../utils/CountriesUtil';
import { UserContext } from '../../UserContext';

interface SchoolFormProps {
  mode: 'add' | 'edit' | 'view';
}


const SchoolForm: React.FC<SchoolFormProps> = ({ mode }) => {
  
  const {user}=useContext(UserContext)
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState<SchoolsDTO>({
    _id: '',
    name: '',
    email: '',
    mainLangugage: '',
    secondaryLanguage: '',
    institution: '',
    type: '',
    country: '',
    region: '',
    casa: '',
    casaId: "",
    phoneNumber: '',
    countryCode:'',
    website: '',
    archived: null,
    dateCreated:'',
    batchCount: 0,
  });
  const [batchData,setBatchTable]=useState<BatchDTO[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false); 
  const [modalMode, setModalMode] = useState<'archive' | 'delete'>('archive');

  const[loading,setLoading]=useState(true)
  const [buttonLoading,setButtonLoading]=useState(false)

  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    
    if (modalMode === 'delete' && schoolData._id ) {
      try {
        const response=await SchoolsService.deleteSchool(schoolData._id);
        showSuccessToast(response);
        navigate('/schools');
      } catch (error:any) {
        
        showErrorToast('Error deleting school: '+error.message);
      }
    } else if (modalMode === 'archive' && schoolData._id) {
      try {
        const response =await SchoolsService.toggleArchive( schoolData._id);
        showSuccessToast(response);
        navigate('/schools');
      } catch (error:any) {
        
        showErrorToast('Error toggling archive school: '+error.message);
      }
    }
    setModalOpen(false); 
  };

//fetch school
  useEffect(() => {
    const fetchSchool = async () => {
       try {
        if ( id) {
          const fetchedData = await SchoolsService.getSchoolById(id);
          setSchoolData(fetchedData.school);
          setBatchTable(fetchedData.batches)
        }else{
          showErrorToast('Error school id not found')
        }
      } catch (error:any) {
        showErrorToast("Error fetching school: "+error.message)
      }finally{
        setLoading(false)
      }
    };

    if ((mode === 'view' || mode === 'edit') && id) {
      fetchSchool();
    }else{
      setLoading(false)
    }
  }, [mode, id]);
  

  const handleCasaIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSchoolData({ ...schoolData, casaId:e.target.value });
    
  };
  
  const validateForm = (): boolean => {
    const missingFields: string[] = [];

    if (!schoolData.name) missingFields.push('Name');
    if (!schoolData.email) missingFields.push('Email');
    if (!schoolData.mainLangugage) missingFields.push('Main Language');
    if (!schoolData.secondaryLanguage) missingFields.push('Secondary Language');
    if (!schoolData.institution) missingFields.push('Institution');
    if (!schoolData.type) missingFields.push('Type');
    if (!schoolData.country) missingFields.push('Country');
    if (!schoolData.region) missingFields.push('Region');
    if (!schoolData.casa) missingFields.push('Casa');
    if (!schoolData.casaId) missingFields.push('Casa ID');
    if (!schoolData.phoneNumber) missingFields.push('Phone Number');
    if (!schoolData.website) missingFields.push('Website');

    if (missingFields.length > 0) {
        showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
        return false;
    }

    return true;
};


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!validateForm()) {
      return 
    }
 

    setButtonLoading(true)

    const { _id, dateCreated,archived,batchCount ,...schoolDataToSend } = schoolData; 

    try {
      if (mode === 'add') {
        await SchoolsService.createSchool( schoolDataToSend);
        showSuccessToast('School created successfully');
      } else if (mode === 'edit' && _id) {
        await SchoolsService.updateSchool( _id, schoolDataToSend);
        showSuccessToast('School updated successfully');
      }
      navigate('/schools');
    } catch (error:any) {
      
      showErrorToast(`Error ${mode === 'add'? 'creating' : 'updating'} school: `+error.message);

    }finally{
      setButtonLoading(false)
    }
  };
 
  const columns = [
    { title: 'Batch Title' },
    { title: 'Book Cover' },
    { title: 'Book Title' },
    { title:'Activation Date'},
    { title:'Expiration date'},

  ];

  if(loading) {
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
        <CircularProgress/>
      </div>
    )
  }

  return (
    <div className={styles.background }>
      <div className={styles.Title}>School Information</div>
      <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
        <div className={styles.row}>
          <div className={styles.field}>
            <label>Name:</label>
            <input
              type="text"
              value={schoolData.name}
              onChange={(e) => setSchoolData({ ...schoolData, name: e.target.value })}
              className={styles.textField}
              disabled={mode === 'view'}
              name="name"
            />
          </div>
          <div className={styles.field}>
            <label>Email Address:</label>
            <input
              type="email"
              value={schoolData.email}
              onChange={(e) => setSchoolData({ ...schoolData, email: e.target.value })}
              className={styles.textField}
              disabled={mode === 'view'}
              name="email"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <Dropdown
              label="Main Language"
              options={Languages}
              selectedOption={schoolData.mainLangugage}
              onSelect={(value) => setSchoolData({ ...schoolData, mainLangugage: value })}
              disabled={mode === 'view'}
            />
          </div>
          <div className={styles.field}>
            <Dropdown
              label="Second Language"
              options={Languages}
              selectedOption={schoolData.secondaryLanguage}
              onSelect={(value) => setSchoolData({ ...schoolData, secondaryLanguage: value })}
              disabled={mode === 'view'}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
          <label>Institution:</label>
          <input type="text" value={schoolData.institution} onChange={(e)=>setSchoolData({...schoolData,institution:e.target.value})} className={styles.textField}       disabled={mode === 'view'} />
          </div>
          <div className={styles.field}>
            <label>Type:</label>
            <input
              type="text"
              value={schoolData.type}
              onChange={(e) => setSchoolData({ ...schoolData, type: e.target.value })}
              className={styles.textField}
              disabled={mode === 'view'}
              name="type"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <Dropdown
              label="Country"
              options={Countries}
              selectedOption={schoolData.country}
              onSelect={(value) => setSchoolData({ ...schoolData, country: value })}
              disabled={mode === 'view'}
            />
          </div>
          <div className={styles.field}>
            <label>Region:</label>
            <input
              type="text"
              value={schoolData.region}
              onChange={(e) => setSchoolData({ ...schoolData, region: e.target.value })}
              className={styles.textField}
              disabled={mode === 'view'}
              name="region"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <label>Casa:</label>
            <input
              type="text"
              value={schoolData.casa}
              onChange={(e) => setSchoolData({ ...schoolData, casa: e.target.value })}
              className={styles.textField}
              disabled={mode === 'view'}
              name="casa"
            />
          </div>
          <div className={styles.field}>
            <label>Casa ID:</label>
            <input
              type="text"
              value={schoolData.casaId}
              onChange={handleCasaIdChange}
              className={styles.textField}
              disabled={mode === 'view'}
              name="casaId"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.field}>
            <label>Phone Number:</label>

            <div className={styles.phoneNumberInputContainer}>
                  <input 
                    type="text" 
                    value={schoolData.countryCode ? `+${schoolData.countryCode}` : "+"} 
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const sanitizedValue = newValue.startsWith('+') ? newValue.slice(1) : newValue;

                      setSchoolData({ ...schoolData, countryCode: sanitizedValue || "" });
                    }}
                    style={{ width: '68px' }} 
                    className={styles.phoneCode}
                    disabled={mode === 'view'}
                  />

                    <input 
                      value={schoolData.phoneNumber}
                      onChange={(e)=>{
                        const input = e.target.value;
                        const filteredInput = input.replace(/\D/g, '');
                        setSchoolData({ ...schoolData, phoneNumber:filteredInput})
                      }}
                      className={`${styles.phoneNumber} ${styles.phoneTextField}`}
                      style={{width:'100%',padding:'10px',height:'56px',fontWeight:400,fontSize:'18px'}}
                    disabled={mode === 'view'}
                      
                    />
                </div>
          </div>
          <div className={styles.field}>
            <label>Website:</label>
            <input
              type="text"
              value={schoolData.website}
              onChange={(e) => setSchoolData({ ...schoolData, website: e.target.value })}
              className={styles.textField}
              disabled={mode === 'view'}
              name="website"
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {mode !== 'view' && (
            <>
              {mode!=="add"&& user?.role==='admin'?
                <>
                  <button
                    type="button"
                    className={styles.DeleteButton}
                    onClick={handleDelete}
                    disabled={buttonLoading}
                  >
                    DELETE
                  </button>
                  <button
                    type="button"
                    className={styles.ArchiveButton}
                    onClick={handleArchive}
                    disabled={buttonLoading}

                  >
                    {schoolData.archived===true?'UNARCHIVE':"ARCHIVE"}
                  </button>
                </>
                :<></>
              }
              <button
                type="submit"
                className={styles.AddButton}
                disabled={buttonLoading}
              >
                {buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}
              </button>
            </>
          )}
        </div>
      </form>

      {mode === 'view' && (
        <div className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <p className={styles.headerText}>School Batches</p>
            
            <Link to="/batches/add" className={styles.addButton}>
              
                <AddCircleIcon/>
              
              <p style={{fontWeight:"500"}}>ADD BATCH</p>
            </Link>
          </div>
          <Table
            columns={columns}
            data={batchData.map((value:BatchDTO,index)=>{
              return {
                'Batch Title':value.title,
                'Book Cover':<img src={value.book?.bookCoverUrl?.toString()} alt="book cover" className={styles.bookCover}/>,
                'Book Title':value.book?.bookTitle,
                'Activation Date':value.createdAt,
                'batchId':value._id
              }
            })}
           />
        </div>
      )}
       <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={modalMode === 'archive' ? schoolData?.archived===true?'Unarchive School':'Archive School' : 'Delete School'}
        parag={modalMode === 'archive' ? `Are you sure you want to ${schoolData.archived===true?'unarchive':'archive'} this school?` : 'Are you sure you want to delete this school? This action cannot be undone  '}
        buttonText={modalMode === 'archive' ? schoolData.archived===true?'Unarchive':'Archive' : 'Delete'}
        mode={modalMode}
      />
    </div>
  );
};

export default SchoolForm;