  import React, { useState, useEffect, useRef, useContext } from 'react';
  import { useParams, useNavigate } from 'react-router-dom';
  import styles from './PublisherForm.module.css';
  import Dropdown from '../../components/Shared/Textfields/FillerDropdown';
  import { PublishersService } from '../../services/PublishersService';
  import {ReactComponent as ImageIcon} from '../../images/Shared/Image.svg'
  import {   CreatePublisherResponseDTO, PublisherFormData } from '../../dto/PublishersDTO';
  import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
  import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
  import { CircularProgress } from '@mui/material';
  import { UserContext } from '../../UserContext';
  import Countries from '../../utils/CountriesUtil'
  interface PublisherFormProps {
    mode: 'add' | 'edit' | 'view';
  }


  const PublisherForm: React.FC<PublisherFormProps> = ({ mode }) => {
    const {user}=useContext(UserContext)
    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const [publisherData, setPublisherData] = useState<PublisherFormData>({
      _id:'',
      publisherIconUrl: null,
      publisherLogoUrl:null,
      fullname: '',
      email: '',
      country: '',
      phoneNumber: '',
      countryCode:'',
      password: '',
      representative: '',
      address: '',
      description: '',
      archived:null
    });
  
    const [logoPreview, setLogoPreview] = useState<string | null>(null);
    const [iconPreview, setIconPreview] = useState<string | null>(null);


    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);

    const [loading,setLoading]=useState(true)
    const [buttonLoading,setbuttonLoading]=useState(false)

    const openArchiveModal = () => {
      setArchiveModalOpen(true);
    };
  
    const closeArchiveModal = () => {
      setArchiveModalOpen(false);
    };
  
    const openDeleteModal = () => {
      setDeleteModalOpen(true);
    };
  
    const closeDeleteModal = () => {
      setDeleteModalOpen(false);
    };
  
    const handleArchiveConfirm = async () => {
      try {
        if (id) {
          const response=await PublishersService.archivePublisher(id);
          showSuccessToast(response)

          navigate('/publishers');
        }else{
          showErrorToast("Error publisher id not found.")
        }
      } catch (error:any) {
        showErrorToast('Error toggling archive publisher: '+error.message)
      }
      closeArchiveModal();
    };
  
    const handleDeleteConfirm = async () => {
      try {
        
        if ( id) {
        const response=await PublishersService.deletePublisher( id);
          showSuccessToast(response)
          navigate('/publishers');
        }else{
          showErrorToast("Error publisher id not found.")
        }
      } catch (error:any) {
        showErrorToast('Error while deleting publisher: '+error.message)
      }
      closeDeleteModal();
    };

    const logoInputRef=useRef<HTMLInputElement>(null);
    const iconInputRef=useRef<HTMLInputElement>(null);
    const handlePasswordBlur=()=>{
      if(publisherData.password.trim().length<6){
        showErrorToast('Password should be longer than 6 characters')
      }
    }
    //fetch publisher
    useEffect(() => {

      const fetchPublisher = async () => {
        try {

          if(!id){
            throw new Error("error getting id")
          }
          const fetchedData = await PublishersService.getPublisherById(id);
          setPublisherData(fetchedData);
          if (fetchedData.publisherLogoUrl) setLogoPreview(fetchedData.publisherLogoUrl);
          if (fetchedData.publisherIconUrl) setIconPreview(fetchedData.publisherIconUrl);
        } catch (error:any) {
          showErrorToast('Error fetching publisher: '+error.message)
        }finally{
          setLoading(false)
        }
      }

      if (id && mode!=="add") {
        fetchPublisher();
        setLoading(true)

      }else{
        setLoading(false)
      }
    }, [mode,id]);

    const handleSubmit =async(e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try{
        console.log("helooooo",mode)
        if(!validateForm()){
          
          return 
        }
        console.log("failed",mode)
        console.log(mode)
        if (mode === 'add') {
          await handleAdd()
          navigate('/publishers');
        } else if (mode === 'edit') {
          await handleUpdate();
          navigate(`/publishers/view/${id}`);
        }
      }catch(error){
        console.error(error)
          return
      }
    };

    const handleAdd=async()=>{
      try{

        setbuttonLoading(true)
        const formData = new FormData();
        formData.append('email', publisherData.email);
        formData.append('fullname', publisherData.fullname);
        formData.append('country', publisherData.country.toLowerCase());
        formData.append('phoneNumber',(publisherData.phoneNumber));
        formData.append('countryCode',(publisherData.countryCode));
        formData.append('representative', publisherData.representative);
        formData.append('description', publisherData.description);
        if (publisherData.publisherLogoUrl) formData.append('publisherLogo', publisherData.publisherLogoUrl);
        if (publisherData.publisherIconUrl) formData.append('publisherIcon', publisherData.publisherIconUrl);
        formData.append('address', publisherData.address);
        formData.append('password', publisherData.password);

        const response:CreatePublisherResponseDTO= await PublishersService.createPublisher(formData)

        showSuccessToast('Publisher created successfully!')
      }catch(error:any){
        showErrorToast('Error creating publisherr: '+error.message)
        throw error
      }finally{
        setbuttonLoading(false)
      }
    }

    const handleUpdate=async()=>{
      try{
        setbuttonLoading(true)
        const formData = new FormData();
        formData.append('uid',publisherData._id);
        formData.append('email', publisherData.email);
        formData.append('fullname', publisherData.fullname);
        formData.append('country', publisherData.country);
        formData.append('phoneNumber',(publisherData.phoneNumber));
        formData.append('countryCode',(publisherData.countryCode));
        formData.append('representative', publisherData.representative);
        formData.append('description', publisherData.description);
        if (publisherData.publisherLogoUrl) formData.append('publisherLogo', publisherData.publisherLogoUrl);
        if (publisherData.publisherIconUrl) formData.append('publisherIcon', publisherData.publisherIconUrl);
        formData.append('address', publisherData.address);

        await PublishersService.updatePublisher(formData);
        showSuccessToast('Publisher updated successfully')
      } catch(error:any){
        showErrorToast('Error updating publisher: '+error.message)
        throw error
      }finally{
        setbuttonLoading(false)
      }
    }

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        setPublisherData({ ...publisherData, publisherLogoUrl: file });
        setLogoPreview(URL.createObjectURL(file));  
      }
    };

    const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        setPublisherData({ ...publisherData, publisherIconUrl: file });
        setIconPreview(URL.createObjectURL(file));  
      }
    };

    const handleLogoClick = () => {
      if(mode==="view"){
        return
      }
      if (logoInputRef.current) {
        logoInputRef.current.click();
      }
    };
  
    const handleIconClick = () => {
      if(mode==="view"){
        return
      }

      if (iconInputRef.current) {
        iconInputRef.current.click();
      }
    };

    const handleCountryChange = (selected: string) => {
      setPublisherData({ ...publisherData, country: selected });
    };

    const validateForm = (): boolean => {
      const missingFields: string[] = [];
      console.log(publisherData)
      if (!publisherData.email?.trim()) missingFields.push('Email');
      if (!publisherData.fullname?.trim()) missingFields.push('Full Name');
      if (!publisherData.phoneNumber?.trim()) missingFields.push('Phone Number');
      if (!publisherData.countryCode) missingFields.push('Country Code');
      if (!publisherData.country?.trim()) missingFields.push('Country');
      if (!publisherData.address?.trim()) missingFields.push('Address');
      if (!publisherData.representative?.trim()) missingFields.push('Representative');
      if (!publisherData.description?.trim()) missingFields.push('Description');
      if (mode === 'add' && !publisherData.password?.trim()) missingFields.push('Password');
      if (mode === 'add' &&publisherData.password?.length < 6) missingFields.push('Password (minimum 6 characters)');
      if (!publisherData.publisherLogoUrl) missingFields.push('Publisher Logo');
      if (!publisherData.publisherIconUrl) missingFields.push('Publisher Icon');
  
      if (missingFields.length > 0) {
          showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
          return false;
      }
  
      return true;
  };
  
    
    if(loading){
      return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
          <CircularProgress/>
        </div>
      )
    }
    return (
      <div className={styles.background}>
        <div className={styles.Title}>Publisher Information</div>
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.row}>
              <div className={styles.field}>
                <label>Name:</label>
                <input
                  type="text"
                  value={publisherData.fullname}
                  onChange={(e) => setPublisherData({ ...publisherData, fullname: e.target.value })}
                  className={styles.textField}
                  disabled={mode==="view"}
                  autoFocus
                  name='fullname'
                />
              </div>
              <div className={styles.field}>
                <label>Email Address:</label>
                <input
                  type="email"
                  value={publisherData.email}
                  onChange={(e) => setPublisherData({ ...publisherData, email: e.target.value })}
                  className={styles.textField}
                  disabled={mode==="view"}
                  name='email'
                />
              </div>
          </div>
          <div className={styles.row}>
              <div className={styles.field}>
              <Dropdown label="Country" options={Countries} selectedOption={publisherData.country} onSelect={handleCountryChange} disabled={mode==="view"}/>
              </div>

              <div className={styles.field}>
                <label>Phone Number:</label>
                  <div className={styles.phoneNumberInputContainer}>
                  <input 
                    type="text" 
                    value={publisherData.countryCode ? `+${publisherData.countryCode}` : "+"} 
                    onChange={(e) => {
                      const newValue = e.target.value;
                      // Remove any leading "+" before updating `publisherData`
                      const sanitizedValue = newValue.startsWith('+') ? newValue.slice(1) : newValue;

                      // If the field is cleared, reset to "+"
                      setPublisherData({ ...publisherData, countryCode: sanitizedValue || "" });
                    }}
                    style={{ width: '68px' }} 
                    className={styles.phoneCode}
                    disabled={mode === 'view'}
                  />

                    <input 
                      value={publisherData.phoneNumber}
                      onChange={(e) => {
                        const input = e.target.value;
                        const filteredInput = input.replace(/\D/g, '');
                        setPublisherData({ ...publisherData, phoneNumber: filteredInput });
                      }}
                      className={`${styles.phoneNumber} ${styles.phoneTextField}`}
                      style={{width:'100%',padding:'10px',height:'56px',fontWeight:400,fontSize:'18px'}}
                    disabled={mode === 'view'}
                      
                    />
                </div>
              </div>
          </div>
          <div className={styles.row}>
            {mode==="add"?
            <div className={styles.field}>
              <label>Password:</label>
              <input
                type="text"
                value={publisherData.password}
                onChange={(e) => setPublisherData({ ...publisherData, password: e.target.value })}
                className={styles.textField}
                name='password'
                onBlur={handlePasswordBlur}
              />
            </div>:<></>}
            <div className={styles.field}>
              <label>Representative:</label>
              <input
                type="text"
                value={publisherData.representative}
                onChange={(e) => setPublisherData({ ...publisherData, representative: e.target.value })}
                className={styles.textField}
                disabled={mode==="view"}
                name='representative'
              />
            </div>
          </div>

          <div className={styles.field}>
            <label>Address:</label>
            <textarea
              value={publisherData.address}
              onChange={(e) => setPublisherData({ ...publisherData, address: e.target.value })}
              className={`${styles.fullWidth} ${styles.addressTextField}`}
              disabled={mode==="view"}
              name='address'
            />
          </div>
          <div className={styles.field2}>
            <label>Description:</label>
            <textarea
              value={publisherData.description}
              onChange={(e) => setPublisherData({ ...publisherData, description: e.target.value })}
              className={`${styles.fullWidth} ${styles.desctextField}`}
              disabled={mode==="view"}
              name='description'
            />
          </div>
          { mode !== 'view' && (<div className={styles.addImageContainer}>
            <div className={styles.addImageSectionContainer}>
              <h1>Publisher Logo</h1>

              <div className={styles.addImageSection}>
              {!logoPreview? 
                <>
                  <div>
                    <ImageIcon />  
                  </div>
                    <p className={styles.addImageParag}>
                      upload or drop image
                    </p>
                </>
                :<img src={logoPreview} alt="Publisher Logo" className={styles.previewImage} />
              }
                <button type="button" className={styles.uploadButton} onClick={handleLogoClick}>
                  Upload
                </button>
                <input
                  type="file"
                  ref={logoInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleLogoChange}
                  name="logo"
                />
              </div>

            </div>

            <div className={styles.addImageSectionContainer}>
              <h1>Publisher Icon</h1>

              <div className={styles.addImageSection}>
                {!iconPreview?
                  <>
                  <div>
                    <ImageIcon/>  
                  </div>
                  <p className={styles.addImageParag}>
                    upload or drop image
                  </p>
                </>
                :<img src={iconPreview} alt="Publisher Logo" className={styles.previewImage} />}
                <button type="button" className={styles.uploadButton} onClick={handleIconClick}>
                  Upload
                </button>
                <input
                  type="file"
                  ref={iconInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>

            </div>
          </div>
        )}
          {mode !== 'view' && (
            <div className={styles.buttonContainer}>
              {mode!=='add' && user?.role==='admin'?
                <>
                <button className={styles.DeleteButton} type="button" onClick={openDeleteModal}>DELETE</button>
                <button className={styles.ArchiveButton} type="button" onClick={openArchiveModal}>{publisherData?.archived===true?'UNARCHIVE':'ARCHIVE'}</button>
                </>
              :<></>}
              <button className={styles.AddButton} type="submit" >{buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}</button>
            </div>
          )}
        </form>

        <ArchiveModal
            setIsOpen={setArchiveModalOpen}

        isOpen={archiveModalOpen}
        onConfirm={handleArchiveConfirm}
        onCancel={closeArchiveModal}
        header={`${publisherData?.archived===true?"Unarchive":'Archive'} Publisher`}
        parag={`Are you sure you want to ${publisherData?.archived===true?"Unarchive":'Archive'} this publisher?`}
        mode="archive"
        buttonText={publisherData?.archived===true?"Yes, Unarchive":"Yes, Archive"}
      />

      {/* Delete Modal */}
      <ArchiveModal
            setIsOpen={setDeleteModalOpen}

        isOpen={deleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={closeDeleteModal}
        header="Delete Publisher"
        parag="Are you sure you want to delete this publisher? This action cannot be undone."
        mode="delete"
        buttonText="Yes, Delete"
      />
      </div>
    );
  }

  export default PublisherForm;