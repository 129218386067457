import React, { useState, useEffect } from 'react';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Shared/Table/CategTable';
import { PublisherResponseDTO, PublishersDTO } from '../../dto/PublishersDTO';
import { PublishersService } from '../../services/PublishersService';
import styles from './Publishers.module.css';
import SubHeader from '../../components/Shared/SubHeader/SubHeader';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material';

interface Column {
    title: string;
}

const Publishers: React.FC = () => {
    const columns: Column[] = [
        { title: 'Logo' },
        { title: 'Name' },
        { title: 'Email' },
        { title: 'PhoneNb' },
        { title: 'Country' },
        { title: 'TotalBooks' },
        { title: 'CreatedDate' },
    ];

    const [publishers, setPublishers] = useState<PublishersDTO[]>([]);
    const [total,setTotal]=useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [activeButton, setActiveButton] = useState(0); 
    const [showModal, setShowModal] = useState(false);
    const [selectedPublisherId, setSelectedPublisherId] = useState<string | null>(null);
    const [mode, setMode] = useState<'archive' | 'delete'>('archive'); 
    const [loading,setLoading]=useState(true);
    const [searchTerm,setSearchTerm]=useState('');
    const navigate = useNavigate();

    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const handleSort = (column: string) => {
        if (sortColumn === column) {
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
          setSortColumn(column);
          setSortDirection('asc');
        }
      };

    const sortedPublishers =React.useMemo(() => {
        if (!sortColumn) return publishers;

        const parseValue = (value: any) => {
            if (value === null || value === undefined) return null;
            if (typeof value === 'string') {
                if (!isNaN(Date.parse(value))) return new Date(value).getTime();
                const numberMatch = value.match(/\d+/);
                if (numberMatch) return parseInt(numberMatch[0], 10);
                return value.toLowerCase();
            }
            return value;
        };

        return [...publishers].sort((a, b) => {
            const aValue = parseValue(a[sortColumn as keyof PublishersDTO]);
            const bValue = parseValue(b[sortColumn as keyof PublishersDTO]);

            if (aValue === null && bValue !== null) return sortDirection === 'asc' ? 1 : -1;
            if (aValue !== null && bValue === null) return sortDirection === 'asc' ? -1 : 1;
            if (aValue === null && bValue === null) return 0;

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            }
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            return 0;
        });
    }, [publishers, sortColumn, sortDirection]);


    const [pageSize, setPageSize] = useState(10);
    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        setLoading(true)
        setPageSize(Number(event.target.value));
    };

    //fetch  publishers
    useEffect(() => {
        const fetchPublishersData = async () => {
            try {
                const archived:boolean= activeButton===1?true:false;

                const response:PublisherResponseDTO = await PublishersService.getPublishers(archived,pageSize,currentPage,searchTerm);
                if(response && response.publishers && Array.isArray(response.publishers)){
                    setPublishers(response.publishers);
                    setTotal(response?.total||0)
                }

            } catch (error:any) {
                showErrorToast('Error fetching publishers: '+error.message)
            }finally{   
                setLoading(false)
            }

        };

        fetchPublishersData();
    }, [activeButton,currentPage,pageSize,searchTerm]);

    
    const handleSearchChange = (value: string) => {
        setLoading(true)
        setCurrentPage(1)
        setSearchTerm(value); 
    };

    const handleEditClick = (index: number): void => {
        const publisher = publishers[index];
        if (publisher) {
            navigate(`/publishers/edit/${publisher._id}`);
        }
    };

    const handleViewClick = (index: number): void => {
        const publisher = publishers[index];
        if (publisher) {
            navigate(`/publishers/view/${publisher._id}`);
        }
    };

    const handlePageChange = (page: number): void => {
        setCurrentPage(page);
    };

    const handleConfirmAction = async (): Promise<void> => {
        if (selectedPublisherId) {
        
            try {
                setLoading(true)
                setSelectedPublisherId(null);
                if (mode === 'archive') {
                    const message=await PublishersService.archivePublisher(selectedPublisherId);
                    showSuccessToast(message);
                } else if (mode === 'delete') {
                    const message=await PublishersService.deletePublisher( selectedPublisherId);
                    showSuccessToast(message);
                }
                setPublishers(publishers.filter(publisher => publisher._id !== selectedPublisherId));
            } catch (error:any) {
                showErrorToast(`Error ${mode === 'archive' ? 'toggling archive' : 'deleting'} publisher: `+error.message);
            }finally{
                setLoading(false)
            }
            
        }
        setShowModal(false);
    };

    const handleCancelAction = (): void => {
        setSelectedPublisherId(null);
        setShowModal(false);
    };

    const handleArchiveClick = (id: string): void => {
        setSelectedPublisherId(id);
        setMode('archive');
        setShowModal(true);
    };

    const handleDeleteClick = (id:string): void => {
        setSelectedPublisherId(id);
        setMode('delete'); 
        setShowModal(true);
    };

    const formatDate = (oldDate:string): string => {
        if(!oldDate){
            return " "
        }
        const date = new Date(oldDate);
        const formattedDate = date.toLocaleDateString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        });
        return formattedDate;
    }

    const handleChangeActiveButtons=(index:number)=>{
        setLoading(true)
        setCurrentPage(1)

        setActiveButton(index)
    }

    const label = [ 
        {
            label_name: "Active",
            index: 0
        }, 
        { 
            label_name: "Archived",
            index: 1
        }
    ]

    return (
        <div className={styles.container}>
            <SubHeader 
                activeButton={activeButton} 
                setActiveButton={handleChangeActiveButtons} 
                dataLength={total} 
                label={'ADD PUBLISHER'} 
                link={'/publishers/add'}
                labelObject={label}
                searchTerm={searchTerm} 
                onChange={handleSearchChange}
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
                exportType='publishers'
            />
            <Table
                handleSort={handleSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                columns={columns}
                data={sortedPublishers?.map((publisher:PublishersDTO) => ({
                    _id: publisher._id,
                    Logo: publisher.publisherLogoUrl,
                    Icon:publisher.publisherIconUrl,
                    Name: publisher.fullname,
                    Email: publisher.email,
                    PhoneNb: publisher.phoneNumber,
                    Country: publisher.country,
                    TotalBooks:publisher.totalBooks ,
                    CreatedDate: formatDate(publisher.dateCreated),
                    Archived:publisher.archived
                }))}
                onEdit={handleEditClick}
                onView={handleViewClick}
                onDelete={handleDeleteClick}
                onArchive={handleArchiveClick}
                loading={loading}
            />
            <div className='w-full flex items-center justify-center'>
                <Pagination3 totalPages={Math.ceil(total / 10)} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
            <ArchiveModal
            setIsOpen={setShowModal}
                isOpen={showModal}
                onConfirm={handleConfirmAction}
                onCancel={handleCancelAction}
                header={`Sure you want to ${mode==='archive' ? activeButton===0?'archive':"unarchive" : 'delete'} this publisher?`}
                parag={`Are you sure you want to ${mode==='archive'?activeButton===0?"archive":'unarchive':'delete'} this publisher?`}
                mode={mode}
                buttonText={mode==='archive' ? activeButton===0?'Yes, archive':"Yes, unarchive" : 'Yes, delete'}

            />

        </div>
    );
};

export default Publishers;