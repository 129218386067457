import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class GradesService {
    public static getGrades = async ( page: number, limit: number, name: string, archived: boolean): Promise<any> => {
        try {
            const params = new URLSearchParams({
                page: String(page),
                limit: String(limit),
                archived: String(archived),
                ...(name && { name })
            });

            const resp = await FetchAuth(endpoint + `/grade/getAllGrades/admin?${params.toString()}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

         

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    }

    public static createGrade = async ( gradeData: any): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/grade/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(gradeData)
            });

           

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    }

    public static getGradeById = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/grade/${_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    }

    public static updateGrade = async ( _id: string, gradeData: any): Promise<any> => {
        try {
            const resp = await FetchAuth(endpoint + `/grade/${_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(gradeData)
            });
 

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    }

    public static deleteGrade = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/grade/${_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                }
            });

         

            const data = await resp.json();
            return data.message;
        } catch (error) {
             throw error;
        }
    }

    public static toggleArchive = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/grade/toggleArchive/${_id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                }
            });

         
            const data = await resp.json();
            return data.message;
        } catch (error) {
             throw error;
        }
    }

    public static getFile = async ( format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportGrades?format=${format}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

     

            const blob = await resp.blob();
            return blob;
        } catch (error) {
            throw error;
        }
    }
}
